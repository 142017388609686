@import url("https://fonts.googleapis.com/css?family=Noto+Sans&display=swap");

@font-face {
  font-family: "MyriadPro";
  font-weight: 400;
  src: url("/assets/fonts/MyriadPro-Light.otf");
}
@font-face {
  font-family: "MyriadPro";
  font-weight: 600;
  src: url("/fonts/MyriadPro-Regular.otf");
}

html,
body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  font-family: "MyriadPro";
  color: #21366c;
}

h2,
h3 {
  font-family: "MyriadPro";
  font-weight: 600;
}
h2 {
  text-align: center;
  color: #21366c;
  font-size: 45px;
}
p { 
  font-size: 18px;
}

label.required:after,
h3.required:after {
  content: '*';
  color: red;
  margin-left: 10px;
  font-size: .9em;
  display: inline-block;
}
h3.required:after {
  color: #fff;
}

.form-check-inline {
   margin-right: 1.75rem;
}
.form-section .row {
    padding: 15px 0 0;
}
.input-group>.custom-file, .input-group>.custom-select, .input-group>.form-control, .input-group>.form-control-plaintext {
  margin-bottom: 10px;
}

label.error {
  color: red !important;
}
label.error.not-required {
  color: #000;
}
label.error.not-required:after {
  content: '';
  display: none;
}
input.error,
select.error {
    border-color: red !important;
    box-shadow: 0 0 5px red !important;
}
input[type="radio"] {
    border-radius: 65%;
}
input:focus, select:focus {
    box-shadow: 0 0 5px #21376c !important;
}
//********************************************** Apply form  **********************************************//
.apply-form {
    position: relative;
    background: rgba(255, 255, 255, 0.7);
    max-width: 330px;
    margin: 60px auto;
    padding: 30px 25px 30px 25px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  }
  .form-control {
    padding: 10px;
    margin: 10px auto;
    display: flex;
  }
  .states {
    padding: 50px;
    position: relative;
  }
  .applybutton {
    width: 100%;
    height: 70px;
    margin: 0 auto;
    display: flex;
    background: #e8eaf0;
    justify-content: center;
    color: #21386e;
    font-size: 45px;
    border-top: 1.5px white solid;
    border-left: 1.5px white solid;
    border-right: 1px white solid;
  }
  .apply-text {
    color: #21386e;
    text-align: left;
    position: relative;
    margin-top: 25px;
    font-size: 20px;
    line-height: 1.3
  }

  @media (max-width: 576px) {
      .applybutton {
          font-size: 20px;
      }
      .apply-form {
        padding: 30px 10px 30px 10px;
      }
  }
  @media (max-width: 298px) {
    .apply-text {
        margin-top: 0;
    }
}

//********************************************** NavBar  **********************************************//
.navContainer {
  border-top: 15px solid #ef4b5e;
  border-bottom: 20px solid #21366c;
  .navbar-toggler {
    margin-right: 25px;
    float: right;
  }
  .logo {
    position: relative;
    margin: 5px 0 0 100px;
    transition: all .25s;
  }
  .collapse {
    padding: 10px;
  }
  .Nav_Bar {
    position: absolute;
    margin: 0;
    top: 10px;
    width: 100%;
    z-index: 100;
  }
  .nav-item {
    line-height: 6.001;
    text-align: center;
    line-height: 1px;
    margin-bottom: -20px;
    background-color: transparent;
    a.nav-link {
      font-size: 16px;
      text-transform: uppercase;
      font-family: "MyriadPro";
      font-weight: 600;
      color: rgb(0, 0, 0);
      position: relative;
      display: inline-block;
      padding-top: 15px;
      padding-bottom: 50px;
      padding-right: 0px;
      &.apply-now {
        color: black;
      }
      &:hover {
        color: #ef4b5e;
      }
      &.active {
        color: white !important;
        background-color: #21366c !important;
        &:after {
          background-color: #21366c !important;
          
        }
      }
      &:after {
        content: " ";
        display: inline-block;
        background: #000;
        width: 2px;
        height: 14px;
        margin-bottom: -2px;
        margin-left: .5rem;
      }
    }
    &:last-of-type {
      a.nav-link {
        &:after {
          background: #fff;
        }
        &.active:after {
          background-color: #21366c;
        }
      }
    }
  }
  #navbarNav li:last-child a {
    border-right: none;
  }
  .navbar-collapse.show .nav-item .nav-link,
  .navbar-collapse.collapsing .nav-item .nav-link {
    padding: 10px 0;
    border-top: 1px solid #21366c;
    width: 100%;
    font-size: 16px;
    line-height: 16px;
    &:after {
      display: none;
      content: '';
    }
  }
  @media (max-width: 1200px) {
    .navbar-brand {
      padding-left: 20px;
      .logo {
        margin: 5px 0 0 0;
      }
    }
  }
  @media (max-width: 1100px) {
    .navbar-brand {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 20px;
      width: 25%;
      .logo {
        margin: 0 0 0 0px;
      }
    }
    .nav-item {
      a.nav-link {
        font-size: 15px;
      }
    }
  }
  @media (max-width: 991px) {
    .nav-item {
      line-height: normal;
      margin-bottom: 0;
      a.nav-link {
        border: none;
      }
    }
    .navbar-brand {
      width: 50%;
    }
  }
  @media (max-width: 400px) {
    .navbar-toggler {
        display: inherit;
    }
  }
}
.navContainer .nav-item:hover a,
.navContainer .nav-item.active:hover a {
  color: #fff;
}

//********************************************** Footer **********************************************//
footer {
  width: 100%;
  margin: 0 auto;
  position: relative;
  background-color: #21366c;
  color: white;
  padding: 20px;

  ul {
    list-style-type: none;
    display: flex;
    justify-content: center;
  }
  a,
  a:hover {
    color: white;
  }
  .footer-links {
    border-right: 1px solid white;
  }
  .footer-items {
    padding: 5px;
  }
  .f1 {
    text-align: center;
    margin: 0 auto;
    line-height: 0;
  }
  .summary {
    padding: 0;
    margin: 0;
    margin-bottom: 25px;
  }
  .ola {
    margin: 0 auto;
    align-content: center;
    text-align: center;
    padding: 60px;
  }
  .sum-one {
    padding: 0px;
    margin: 0;
    margin-bottom: 25px;
  }

  .sum-two {
    padding: 0px;
    margin: 0;
    margin-bottom: 25px;
  }

  .sum-three {
    padding: 0px;
    margin: 0;
    margin-bottom: 25px;
  }

  @media (max-width: 781px) {
    .footer-links {
      border: none;
    }
    ul {
      display: block !important;
      text-align: center;
      margin-bottom: 20px;
      padding-bottom: 10px;
    }
  }
}
.footer-links:last-of-type {
  border-right: none;
}

//********************************************** Map **********************************************//
.map-container {
  background-image: url("/img/originalhero.jpg");
  background-size: cover;
  background-clip: border-box;
  background-repeat: no-repeat;
  border-bottom: 50px solid #ef4b5e;
  height: auto;
  width: auto;
  .form-control {
    padding: 10px;
    margin: 10px auto;
    display: flex;
  }
  .states {
    padding: 50px;
    position: relative;
  }
  .applybutton {
    width: 100%;
    height: 70px;
    margin: 0 auto;
    display: flex;
    background: #e8eaf0;
    justify-content: center;
    color: #21386e;
    font-size: 44px;
    border-top: 1.5px white solid;
    border-left: 1.5px white solid;
    border-right: 1px #bababa solid;
    transition: all .25s;
  }
  .subheader {
    font-size: 22px;
    font-weight: 600;
    padding-left: 50px;
    line-height: 1.1;
    padding-right: 25px;
  }
  .map-text {
    font-size: 30px;
    color: #21366c;
    position: relative;
    transition: all .25s;
    ul {
      margin-left: 10%;
      margin-top: -75px;
    }
  }
  .loan-amount-text {
    display: flex;
    margin-bottom: -1px;
    position: relative;
    bottom: -10px;
    left: 10px;
  }
  .state-form {
    margin-top: -15px;
    display: flex;
  }
  .loan-form {
    padding: 0px;
    line-height: 0;
  }
  
  @media (max-width: 1200px) {
    .applybutton {
      font-size: 39px;
      padding-top: 7px;
    }
  }
  @media (max-width: 1150px) {
    .map-text {
      font-size: 25px;
    }
  }
  @media (max-width: 1115px) {
    .applybutton {
      font-size: 33px;
      padding-top: 7px;
    }
  }
  @media (max-width: 998px) {
    .map-text ul {
      margin-top: 0;
    }
  }
}
@media (max-width:989px) {
  .map-container,
  .map-container-contact-us,
  .map-container-review {
    background: #ccc;
    .map-row {
      margin: 0 auto;
      margin-top: 0;
    }
    .apply-form {
      margin: 35px auto;
      max-width: 100%;
    }
  }
  .map-row,
  .bg-container {
    background-image: url("/img/originalhero.jpg");
    background-size: cover;
    background-clip: border-box;
    background-repeat: no-repeat;
  }

}
@media (max-width: 450px) {
  .map-container,
  .map-container-contact-us,
  .map-container-review {
    .apply-form {
      max-width: 90%;
    }
  }
}
//** HOME SCSS ** //

//********************************************** HIW: include section ***************************************//
.hiw-container {
  background-image: url("/img/hiwBG.jpg");
  background-size: cover;
  background-clip: border-box;
  background-repeat: no-repeat;
  border-bottom: 10px solid #ef4b5e;
  .hiw-img.row {
    margin-top: 15px;
  }
  .icon-box {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    .icon-box-img {
      min-height: 100px;
      position: relative;
      flex: auto;
      display: flex;
      flex-direction: row;
      margin: auto;
    }
  }
  .apply-first {
    border-right: 1px solid #21366c;
    display: inline-block;
    text-align: center;
  }
  .apply-img {
    position: relative;
    margin: 10px 0 0 50px;
  }
  .approved-second {
    border-right: 1px solid #21366c;
    position: relative;
    text-align: center;
    img {
      max-height: 90px;
      margin-top: 10px;
    }
  }
  .funded-third {
    border-right: 1px solid #21366c;
    text-align: center;
  }
  .repay-last {
    position: relative;
    text-align: center;
  }
  .hiw-text {
    color: #21366c;
    padding: 5px;
    font-size: 20px;
    line-height: 1.2;
    margin-top: 5px;
    text-align: center;
    b {
      font-family: "MyriadPro";
      font-weight: 600;
    }
  }
  .learn-more {
    position: relative;
    margin: 25px auto 5px;
    text-align: center;
    background: #21366c;
    color: white;
    padding: 4px 20px 2px;
    font-family: "MyriadPro";
    font-size: 25px;
    display: inline-block;
    -webkit-font-smoothing: unset;
  }
  @media (max-width:991px) {
    .approved-second,
    .funded-third,
    .apply-first {
        border-right: none;
    }
  }
  @media (max-width: 767px) {
    .apply-img {
      margin: 0 0 0 40px;
    }
    .apply-first {
      border: none;
    }
    .approved-second,
    .funded-third,
    .repay-last {
      border: none;
      margin: 20px 0 0 10px;
    }
    .approved-second,
    .funded-third,
    .apply-first {
      border-bottom: 1px solid #21366c !important;
    }
    .hiw-text {
      font-size: 20px;
      top: 0;
    }
  }
}

//********************************************** Log In Section ***********************************//

.log-in-container {
  width: 100%;
  margin: 0 auto;
  border-bottom: 10px solid #ef4b5e;
  background-color: #f5f5f5;
  //** log in form **//
  .title {
    color: white;
    position: relative;
    margin: 30px 0 0 70px;
    text-align: center;
    .mgTp {
      margin-top: 10%;
    }
    .topBtn {
      margin-top: 15px;
    }
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #8d95a1 !important;
      opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #8d95a1 !important;
    }
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #8d95a1 !important;
    }
  }
  .login-form {
    background-color: #a9b1c6;
    width: 80%;
    margin: 0 auto;
  }
  font {
    position: relative;
    left: 45px;
  }
  .btn {
    background-color: transparent;
    border: none;
    position: relative;
    right: 36px;
    bottom: 10px;
    &:hover {
      background-color: transparent;
      border: none;
    }
    float: right;
  }
  .banner-text-one {
    text-align: center;
    // right: 150px;
    // position: relative;
    // padding: 20px;
    color: #21366c;
  }
  .banner-text-two {
    text-align: center;
    // right: 150px;
    // position: relative;
    // padding: 20px;
    // top: 25px;
    color: #21366c;
    position: relative;
  }
  .banner-text-three {
    text-align: center;
    // right: 126px;
    // position: relative;
    // padding: 20px;
    // top: 15px;
    color: #21366c;
  }
  b {
    font-size: 20px;
    font-family: "MyriadPro";
    font-weight: 600;
  }
  .banner-img {
    position: relative;
  }
  .star-row {
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    display: flex;
    padding: 45px 0;
    .row {
      flex: auto;
      padding-right: 25px;
      .log-in-banner {
        flex-direction: column;
        justify-content: center;
        display: flex;
        b {
          display: block;
        }
        span {
          display: block;
        }
      }
      .banner-text span {
        font-size: 18px;
      }
    }
  }
  .logIn-bootstrap {
    background: #21366c;
    flex-grow: 1;
    flex-direction: column;
    background: #21366c;
    justify-content: center;
    display: flex;
  }
}
.log-in-container .auth {
  display: flex;
}

@media (max-width: 560px) {
    .star-row {
        padding: 20px !important;
    }
}
@media (max-width: 1200px) {
  .log-in-container {
    .star-row {
      .row {
        .log-in-banner {
          span {
            display: block;
          }
        }
      }
    }
  }
}

//********************************************** Trust Pilot ***********************************//

.trust-pilot-text {
  text-align: center;
  color: #21366c;
  font-size: 45px;
  font-family: "MyriadPro";
  font-weight: 900;
  margin-bottom: 0;
}

//** HIW PAGE SCSS ** //
.map-form {
  h1 {
    font-size: 50px;
    font-family: "MyriadPro";
    font-weight: 600;
    margin-top: 25px;
    padding-left: 50px;
  }
  .subtitle {
    font-size: 20px;
    font-family: "MyriadPro";
    font-weight: 600;
  }
  .bg-container {
    .vector-bg {
      top: 15%;
      left: 10%;
      position: absolute;
    }
  }
  @media (min-width: 990px) {
    &.no-form-margin .apply-form {
      margin-top: 0;
    }
  }
  @media (max-width: 800px) {
    .bg-container {
      .vector-bg {
        left: 0;
        top: 35%;
      }
    }
  }
}

.installment-loan {
  padding: 50px 50px;
  .installment-loan-title {
    line-height: 50px;
    font-size: 50px;
    font-weight: bolder;
  }
  .installment-loan-text {
    font-size: 27px;
    line-height: 30p;
  }
}
.installment-row {
  background-color: #ececec;
  border-bottom: 10px solid #21366c;
}

.req-schedule {
  border-bottom: 10px solid #ef4b5e;
  text-align: center;
  font-size: 30px;
  .req-title,
  .schedule-title {
    font-size: 45px;
    font-weight: bolder;
    padding: 50px 0px 25px 0px;
  }
  ul {
    text-align: left;
    list-style-position: inside;
    width: 85%;
    margin: 0 auto;
    padding-left: 0;
    li {
      line-height: 1.3;
    }
  }
  .schedule {
    background-color: #dddee0;
    padding: 15px 15px 95px;
  }
  .req {
    background-color: #aeaeae;
    padding: 15px 15px 95px;
  }
  .req-text {
    width: 85%;
    margin: auto;
    text-align: left;
    line-height: 1.3;
  }
  .schedule-one,
  .schedule-two,
  .schedule-three {
    text-align: left;
    list-style-position: inside;
    width: 85%;
    margin: 0 auto 25px;
    line-height: 1.3;
  }
}
.apply-hiw,
.approve-hiw,
.funded-hiw,
.repay-hiw {
  position: relative;
  text-align: center;
  padding: 30px 40px;
}
.hiw-icons {
  .icon-row {
    display: flex;
    justify-content: center;
    & > div {
      flex: auto;
      text-align: center;
      max-width: 310px;
    }
    @media (max-width: 650px) {
      & > div {
        max-width: 250px;
        padding: 30px 10px;
      }
    }
  }
}
.apply-hiw-img {
  margin: -10px -35px 0px 0px;
}
.hiw-h {
  font-size: 25px;
  font-weight: bolder;
  padding-top: 20px;
}
.hiw-p {
  font-size: 21px;
  width: 100%;
}
.approve-hiw:after {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  right: 50%;
  bottom: 0;
  content: " ";
  border-bottom: 1px solid #21366c;
  border-left: 1px solid #21366c;
}
.apply-hiw:after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  right: 0;
  bottom: 0;
  content: " ";
  border-bottom: 1px solid #21366c;
  border-right: 1px solid #21366c;
}
.funded-hiw:after {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  right: 0;
  bottom: 50%;
  content: " ";
  border-top: 1px solid #21366c;
  border-right: 1px solid #21366c;
}
.repay-hiw:after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  right: 50%;
  bottom: 50%;
  content: " ";
  border-top: 1px solid #21366c;
  border-left: 1px solid #21366c;
}
@media (max-width: 987px) {
  .title-p-hiw {
    margin: auto;
  }
  .title-hiw {
    text-align: center;
    margin: auto;
  }
}
@media (max-width: 1200px) {
  .map-form h1 {
    text-align: center;
    padding-left: 0;
    .subtitle {
      display: block;
    }
  }
}
@media (max-width: 723px) {
  .installment-loan-title {
    line-height: 1 !important;
  }
}
@media (max-width: 525px) {
  .apply-hiw::after,
  .approve-hiw:after,
  .funded-hiw:after,
  .repay-hiw:after {
    border: none;
  }
}

//** APPLY PAGE SCSS ** //
.apply-page {
  padding-top: 35px;
  .apply-title {
    color: #ef4b5e;
    font-size: 35px;
    font-weight: bolder;
    font-family: Arial, Helvetica, sans-serif;
  }
  .required-notification {
    font-size: 18px;
    font-family: 'Arial';
    color: #000;
    font-weight: 500;
    margin-bottom: 0;
  }
  .step {
    .apply-star {
      background: url('/img/vectorbanner.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 27px;
      width: 58px;
      image-rendering: -webkit-optimize-contrast;
      display: inline-block;
    }
    p {
      display: inline-block;
      vertical-align: middle;
      color: #000;
    }
    &.active {
      .apply-star {
        background: url('/img/apply-active.png');
        height: 40px;
        width: 72px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
      p {
        font-family: 'MyriadPro';
        font-weight: 600;
        margin-top: -11px;
      }
    }
  }

  form.apply-now {
    position: relative;
    .form-page {
      display: none;
      &.active {
        display: block;
      }
    }
    .form-title {
      background-color: #ef4b5e;
      margin: 10px auto 0;
      padding: 25px 20px 10px 20px;
      h3 {
        color: white;
        font-size: 16px;
      }
    }
    label {
      font-weight: 400;
      margin-bottom: 0;
      font-family: Arial;
      color: #000;
    }
    input.form-control,
    select.form-control {
      margin-top: 0;
      border-radius: 0;
      border: 1px solid #e0e0e0;
      box-shadow: 0px 0px 2px #e0e0e0;
      &.small-input {
        max-width: 70px;
        margin-left: 15px;
        margin-right: 5px;
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
    .add-top-padding {
      padding-top: 10px;
    }
    .form-section {
      padding-bottom: 25px;
      .row.first-row {
        margin-top:15px;  
      }
    }
    .sms-checkbox {
      padding-top: 10px;
      margin-bottom: 15px;
    }
    .sms-terms {
      color: #037aad;
      font-family: 'MyriadPro';
      font-weight: 600;
    }
    .military-container {
      background: #cecece;
      padding-top: 25px;
      padding-bottom: 55px;
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
      height: auto;
      p {
        font-family: 'MyriadPro';
        font-weight: 600;
        font-size: 24px;
        color: #000;
      }
      label {
        font-family: 'MyriadPro';
        font-weight: 600;
        font-size: 18px;
      }
      input[type="checkbox"] {
        margin-top: .2rem;
      }
      .form-check {
        margin-bottom: 10px;
      }
      p.warning {
        color: red;
        margin: 0px auto;
        padding: 25px 20px 10px 0px;
        font-size: 30px;
        font-family: "MyriadPro";
        font-style: italic;
        font-weight: normal;
        line-height: 1.2;
      }
    }
    .apply-button {
      position: absolute;
      color: #fff;
      padding: 10px 50px;
      font-size: 25px;
    }
    #applySubmit {
      bottom: 10px;
      right: 10px;
      background: #21366c;
      &:hover {
        background: #ef4b5e;
      }
    }
    #applyBack {
      bottom: 10px;
      left: 10px;
      background: #afafaf;
      display: none;
      &:hover {
        background: #888888;
      }
    }
    .pay-date-container p.date-error {
      font-size: 20px;
      color: red;
      font-style: italic;
      font-weight: bold;
    }
    .decision-container {
      border: 1px solid #21366c;
      padding: 25px 15px;
      text-align: center;
      display: inline-grid;
      margin-bottom: 105px;
      p {
        font-size: 28px;
      }
      #appError {
        color: red;
        font-style: italic;
      }
    }
    .adverse {
        font-size: 25px;
        text-decoration: underline;
    }
    .second-pay {
        padding-top: 10px;
        padding-bottom: 50px;
    }
    .bank-age {
        padding-bottom: 50px;
        margin: 0px;
    }
    .pay-date-container {
      padding-top: 25px;
      padding-bottom: 55px;
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
      height: auto;
      p {
        font-size: 25px;
      }
    }
  }

  @media (max-width: 1007px) {
    .ol {
      display: block !important;
      li {
        padding: 0px !important;
        margin: 0px !important;
        font-size: 30px !important;
      }
    }
    .step {
      text-align: center;
      .apply-star {
        display: block;
        text-align: center;
        margin: 0 auto 5px;
      }
    }
  }
}
@media (max-width: 767px) {
  
  .apply-page {
    width: 90%;
    margin: 0 auto;
    .apply-title {
      text-align: center;
    }
    .step {
      display: none;
      &.active {
        display: block;
      }
    }
    .input-group {
      margin-bottom: 10px;
    }
    form.apply-now .apply-button {
      padding: 10px 35px;
    }
    .checkExample.show {
      height: auto;
      padding: 35px 0 10px;
      img {
        max-width: 100%;
      }
    }
  }
}


//** Rates & Terms PAGE SCSS ** //

.randttitle {
  color: red;
  font-size: 50px;
  font-weight: bolder;
  font-family: "MyriadPro";
  font-weight: 600;
  padding: 0 0px 0px 50px;
  line-height: 50px;
  margin-top: 25px;
  margin-bottom: 0px;
}
h4 span {
  color: #21366c;
  font-size: 25px;
  font-family: "MyriadPro";
  font-weight: 600;
}
.form-control {
  padding: 10px;
  margin: 10px auto;
  display: flex;
}
.states {
  padding: 50px;
  position: relative;
}

.cs-container {
  height: auto;
  .sub-text-wi {
    padding-bottom: 20px;
    h6 {
      font-size: 20px;
      color: #ef4b5e;
      margin: 20px;
    }
  }
  .cs-title,
  h4 {
    color: #ef4b5e;
    font-size: 40px;
    font-family: "MyriadPro";
    font-weight: 600;
    margin: 10px 0px 40px 50px;
    font-weight: bolder;
  }
  figure {
    display: inline-block;
    text-align: center;
  }
  figure img {
    vertical-align: top;
  }
  figure figcaption {
    text-align: center;
  }

  .cert-text {
    padding: 50px;
    margin: 90px;
  }
  
  @media (max-width: 781px) {
    .contact-us-link {
      border: none;
    }
    ul {
      display: block !important;
      text-align: center;
      margin-bottom: 20px;
      padding: 0px 0px 10px 0px;
    }
    h5 {
      text-align: center;
      padding: 10px 0px 10px 0px;
      margin: 0px;
    }
  }
  .newul {
    list-style-type: disc;
    display: block;
    padding: 5px;
    margin: 0px;
  }
  .newli {
    border: none;
  }
}
.click-link {
  color: #2ba6cb !important;
}
@media (max-width: 660px) {
  .randttitle {
    line-height: normal;
  }
}
@media (max-width: 989px) {
  svg {
    margin-left: -10%;
  }
}
@media (max-width: 500px) {
  .randttitle {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 0 0 0 0;
  }
  .map-container .subheader {
    width: 90%;
    padding: 0 0 0 0;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 400px) {
  .map-container .map-text ul {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 1200px) {
    .applybutton {
      font-size: 39px;
      padding-top: 7px;
    }
  }
  @media (max-width: 1150px) {
    .map-text {
      font-size: 25px;
    }
  }
  @media (max-width: 1115px) {
    .applybutton {
      font-size: 33px;
      padding-top: 7px;
    }
    .apply-text {
      font-size: 18px;
    }
  }
  @media (max-width: 311px) {
    .applybutton {
      font-size: 22px;
      padding-top: 7px;
    }
  }
//** reviews PAGE SCSS ** //

.reviewtitle {
  font-size: 40px;
  font-family: "MyriadPro";
  font-weight: 600;
}
.trustpilot-container-row .reviewtitle {
  margin-top: 25px;
}
.map-container-review .trustpilot-widget {
    margin-top: -55px;
}
.map-container-review {
  background-color: #f5f5f5;
  background-size: cover;
  background-clip: border-box;
  background-repeat: no-repeat;
  border-bottom: 50px solid #ef4b5e;
  padding-bottom: 10px;
  height: auto;
  width: auto;
}
@media (max-width: 1170px) {
  .map-container-review .trustpilot-widget {
    margin-top: -25px;
  }
}
@media (max-width: 998px) {
  .reviewtitle {
    text-align: center;
  }
}
//** Signin PAGE SCSS ** //

.map-container-signin {
  background-image: url("/img/originalhero.jpg");
  background-size: cover;
  background-clip: border-box;
  background-repeat: no-repeat;
  // background-position: 0px -70px;
  height: 550px;
  border-bottom: 10px solid #ef4b5e;
  .apply-form-signin {
    position: relative;
    margin: 60px;
  }
}
.signin-head {
  font-size: 50px;
  position: relative;
  text-align: left;
  transition: all .25s;
}
.signin-text { transition: all .25s; }
.signin-text ul {
  padding-left: 25px;
}
.signin-text ul li {
  font-size: 33px;
  font-weight: 600;
  font-family: 'MyriadPro';
  line-height: 1.3;
  transition: all .25s;
}
.Estartlogo {
  text-align: center;
  .e-signin-logo {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    transition: all .25s;
  }
}
.signin-hero {
  margin: 50px 0px 0px 0px;
}
@media (max-width: 767px) {
  .signin-text {
    margin-top: 45px;
  }
  .signin-head {
    text-align: center;
  }
  .signin-text div {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 600px) {
  .Estartlogo .e-signin-logo {
    max-width: 150px;
  }
  .signin-head {
    font-size: 40px;
  }
  .signin-text ul li {
    font-size: 23px;
  }
}

.full-section {
  padding: 30px 0;
}

//** Contact-us page SCSS ** //

.map-container-contact-us {
  background-color: #d5d9e3;
  border-bottom: 50px solid #ef4b5e;
  padding-bottom: 10px;
  height: auto;
  width: auto;
}
.cust-serv-container {
  display: flex;
  .row {
    width: 100%
  }

  h2 {
    color: #ef4b5e;
    font-size: 50px;
    font-family: "MyriadPro";
    font-weight: 600;
    margin-top: 25px;
    text-align: left;
  }
  .span {
    font-size: 20px;
    font-family: "MyriadPro";
    font-weight: 400;
    margin-top: 20px;
  }
  h3 {
    padding-top: 30px;
  }
  .container-content {
    padding: 0 15px 0 50px;
  }
}
@media (max-width: 989px) {
  .cust-serv-container .container-content {
    padding: 0 15px 0 15px;
  }
}
@media (max-width: 1112px) {
  .cust-serv-container {
    display: block !important;
  }
}
.bottom-content-container {
  border-bottom: 10px solid #ef4b5e;
  padding: 0 50px;
  a {
    color: #ef4b5e;
  }
  .contact-form {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    padding: 15px 25px;
    margin-bottom: 25px;
    margin-top: 25px;
    .question-contact {
      height: 300px;
      text-align: start;
    }
    .complain-button {
      padding: 15px 45px;
      background-color: #21366c;
      color: white;
      font-size: 22px;
      transition: all .25s;
      &:hover {
        background-color: #ef5566;
      }
    }
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #000;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #000;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #000;
    }
  }

  h2 {
    color: #ef4b5e;
    font-size: 40px;
    font-family: "MyriadPro";
    font-weight: 600;
    text-align: left;
    &.top-h2 {
      margin-top: 25px;
      margin-bottom: 45px;
    }
  }
  &.bold-p {
    p,
    li {
      font-weight: 600;
    }
  }
  ul {
    padding-left: 25px;
    li {
      font-size: 18px;
    }
  }
  .head-section-contact-us {
    margin-top: 5px;
    color: #ef4b5e;
    font-size: 30px;
    font-family: "MyriadPro";
    font-weight: 600;
    margin-bottom: 0;
  }
  h3 {
    margin: 20px 0px 0px 0px;
    font-size: 22px;
  }
  h5 {
    font-size: 22px;
    font-family: "MyriadPro";
    font-weight: 600;
    margin-top: 25px;
  }
  @media (max-width: 781px) {
    .contact-us-link {
      border: none;
    }
  }
}

@media (max-width: 500px) {
  .bottom-content-container {
    padding: 0 20px;
  }
}
.inquiry-p {
  margin-bottom: 0;
}
.inquiry-p3 {
  font-size: 20px;
  margin: 20px 0;
}
.contact-us-link {
  margin-left: -20px;
}


.modal-content {
  color: #000;
  font-family: 'MyriadPro';
  font-weight: 600;
}
.states-we-service {
  margin-top: 45px;
  margin-bottom: 35px;
  h5 {
    margin-bottom: 0;
    font-size: 22px;
  }
  ul {
    margin: 0px;
    padding: 0px;
  }
  ul {
    list-style: none;
    display: flex;
    color: #21366c;
  }
  li {
    margin: 0px 0px 0px 10px;
    padding: 0px 10px 0px 0px;
    border-right: 1px solid #21366c;
    color: #21366c;
    a {
      font-weight: 400;
      color: #21366c;
    }
  }
  li:first-child{
      margin: 0;
  }
  li:last-child {
    border: none;
  }
}
@media (max-width: 700px) {
  .states-we-service ul {
    display: block;
    margin-left: -10px;
    li {
      font-size: 18px;
      display: inline-block;
      &:first-child {
        margin-left: 10px;
      }
    }
  }
}
h2.red-h2 {
  color: #ef4b5e;
  font-size: 40px;
  font-family: "MyriadPro";
  font-weight: 600;
  margin: 10px 0px 40px;
  text-align: left;
}
.checkExample {
    padding: 0 0;
    text-align: center;
    max-height: 350px;
    transition: all .5s;
    height: 0;
    overflow: hidden;
    &.show {
      height: auto;
      padding: 55px 0 0;
    }
}

#showExample {
    color: #fff;
    text-decoration: underline;
}

.loader-image {
  width: 262px;
  height: 249px;
  background: url('/img/Esignin.png');
  position: relative;
  margin: 25px auto;
  &:before {
    animation-name: loader;
    animation-duration: 4s;
    content: " ";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    position: absolute;
    animation-iteration-count: infinite;
  }
}
 

@keyframes loader {
  from {left: 0;}
  to {left: 262px;}
}

#contact-sent {
    font-size: 25px;
    padding: 10px 0;
    line-height: 30px;
    display: none;
}
#contact-error {
    color: red;
    font-size: 25px;
    padding: 15px 0 0;
    display: none;
}

.card-header h4 button.btn {
    text-align: left;
}
.accordion {
  .card {
    background: transparent;
    border: none;
    .card-header {
      background-color: transparent;
      padding: .15rem 0;
      border: none;
      button.btn.btn-link {
        color: #21366c;
        font-weight: 400;
        font-family: 'Imago';
        width: 100%;
      }
    }
    .card-body {
      background: #fff;
    }

  }
}